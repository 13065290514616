import React from 'react';
import { BrowserRouter as Router, Link, Route, Routes } from 'react-router-dom';
import './App.css';
import ScrollToTop from './scrollToTop';
import { Tech } from './Tech';
import { Projects } from './Projects';
import gulpLogo from './icons/gulp.gif';

function Home() {
  return (
    <div className="home">
      <main className="main">
        <div className="text">
          <div className="name">Valentin Mihaylov</div>
          <div className="subtitle">Individuelle Software Lösungen<br />für Ihr Unternehmen</div>
        </div>
      </main>
      <MainContent />
    </div>
  );
}

function MainContent() {
  const servicesText1 = "Als selbstständiger Software-Entwickler biete ich fundierte Lösungen für Ihr Unternehmen und Projekte an. Sind Sie auf der Suche nach einer Individualsoftware oder wünschen Sie sich Unterstützung bei einem bestehenden Projekt oder Aufgabenstellung? Ich bin Ihr Ansprechpartner!";
  const servicesText2 = "Ich bin in mehreren Technologien sicher wie Java, JEE, SQL (und PL/SQL), git, JavaScript. Kostengünstige Outsourcing Lösungen biete ich Ihnen ebenso gerne an. Zudem schafft meine Leidenschaft für Secure Coding einen Mehrwert für meine Kunden.";
  const projectsText1 = "Ich konnte bereits in verschiedenen Branchen Erfahrung sammeln. Ich war als Software-Entwickler und Berater an Projekten in den Branchen Telekommunikation, Automotive, Banken und Versicherung sowie Loyalitätsprogramme erfolgreich tätig.";
  const aboutText1 = "Ich blicke auf eine langjährige Erfahrung in der IT Beratung zurück. Als Absolvent der Universität Karlsruhe weiß ich, eine gute Ausbildung zu schätzen und bilde mich kontinuierlich weiter aus. So zum Beispiel habe ich mich als Requirements Engineer und als Secure Web Application Engineer zertifizieren lassen und habe mich in Secure Coding erfolgreich schulen lassen.";
  const aboutText2 = "Meine Interessen sind Softwareentwicklung, Datenbanken, IT-Sicherheit, Algorithmen and Datenstrukturen, Mobile App Development sowie Computer Netzwerke.";
  const aboutText3 = "Erfahren Sie gerne mehr über mich! Auf Anfrage schicke ich Ihnen einen ausführlichen Lebenslauf zu.";

  return (
    <>
      <section className="services">
        <p className="text">{servicesText1}</p>
        <p className="text">{servicesText2}</p>
        <Link to="/tech">
          <button>Services & Technologien</button>
        </Link>
      </section>
      <section className="projects">
        <div className="projects-inner">
          <p className="text">{projectsText1}</p>
          <Link to="/projects"><button>Projekte</button></Link>
        </div>
      </section>
      <section className="about">
        <h2>Über mich</h2>
        <p className="text">{aboutText1}</p>
        <p className="text">{aboutText2}</p>
        <p className="text">{aboutText3}</p>
      </section>
    </>
  );
}

function App() {
  return (
    <Router>
      <ScrollToTop />
      <Header />
      <div className='site'>
        <Routes>
          <Route path="/" exact element={<Home />}>
          </Route>
          <Route path="/tech" element={<Tech />}>
          </Route>
          <Route path="/projects" element={<Projects />}>
          </Route>
        </Routes>
      </div>
      <Footer />
    </Router>
  );
}

function Header() {
  const homeLogo = <><span className='colorGray'>Mihaylov</span><span className='colorBlue'>Software</span></>;
  return (
    <header>
      <nav>
        <ul className="nav-links">
          <li className="nav-link-to-home"><Link to="/">{homeLogo}</Link></li>
          <li><Link to="/tech">Services & Technologien</Link></li>
          <li><Link to="/projects">Projekte</Link></li>
        </ul>
      </nav>
    </header>
  );
}

function Footer() {
  const today = new Date();
  return (
    <footer>
      <div className='name'>
        <p>© {today.getFullYear()} Valentin Mihaylov</p>
      </div>
      <div className='profiles'>
        <p>
          <a href='https://www.linkedin.com/in/waliu' target='_blank' rel="noopener noreferrer">
            <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="mercado-match" width="24" height="24" focusable="false">
              <path d="M20.5 2h-17A1.5 1.5 0 002 3.5v17A1.5 1.5 0 003.5 22h17a1.5 1.5 0 001.5-1.5v-17A1.5 1.5 0 0020.5 2zM8 19H5v-9h3zM6.5 8.25A1.75 1.75 0 118.3 6.5a1.78 1.78 0 01-1.8 1.75zM19 19h-3v-4.74c0-1.42-.6-1.93-1.38-1.93A1.74 1.74 0 0013 14.19a.66.66 0 000 .14V19h-3v-9h2.9v1.3a3.11 3.11 0 012.7-1.4c1.55 0 3.36.86 3.36 3.66z"></path>
            </svg>
          </a>
        </p>
        <p>
          <a href='https://www.xing.com/profile/Valentin_Mihaylov' target='_blank' rel="noopener noreferrer">
            <svg fill="none" xmlns="http://www.w3.org/2000/svg" height="30" width="30">
              <path d="M1.805 5.984l3.635 6.479L0 22.018h6.491l5.4-9.555-3.634-6.48H1.805z" fill="#0698A0"></path>
              <path d="M21.122 0l-10.49 18.642L17.396 31h6.582l-6.88-12.358L27.6 0h-6.478z" fill="#B7DF4B"></path>
            </svg>
          </a>
        </p>
        <p>
          <a href='https://www.gulp.de/gulp2/home/profil/mihaylov-software' target='_blank' rel="noopener noreferrer">
            <img src={gulpLogo} alt='GULP Profil' height={25} />
          </a>
        </p>
      </div>
    </footer>
  );
}

export default App;

