import React from 'react';
import './Projects.css';

export function Projects() {

  const projects = [
    { period: '2024.03 - 2024.08', role: 'Senior Softwareentwickler, Architekt', place: 'München', industry: 'E-Commerce', description: 'Preisvergleich im Rahmen einer Loyalty Program', techStack: 'Java 21, quarkus 3, WebServices (SOAP/REST), docker, git, bash', tools: 'IntelliJ, postman, bitbucket, macOS' },
    { period: '2023.09 - 2024.02', role: 'Senior Softwareentwickler', place: 'Cadolzburg (bei Nürnberg)', industry: 'AgriTech', description: 'Fahrzeugdiagnose – Management von ECU Steuergeräten.', techStack: 'Java 17, SpringBoot 3, react, docker, WebServices (SOAP/REST), git, bash', tools: 'IntelliJ, eclipse, github, bitbucket, jenkins' },
    { period: '2021.04 - 2023.06', role: 'Senior Softwareentwickler', place: 'Innsbruck', industry: 'Banking', description: 'Mehrere fachliche Module aus einem Monolythen wurden umgebaut oder neu geschrieben damit sie als Microservices in das private Cloud deployed werden können. Qualität wurde durch umfangreiche Integrationstests sichergestellt.', techStack: 'Java 11, quarkus, kafka, postgres, OIDC/Oauth2, OWASP Top 10, lombok, mapstruct, REST, OpenAPI, git', tools: 'IntelliJ IDEA, postman, swagger, DBeaver, maven, k8s, docker, istio, gitlab, helm, splunk, dynatrace, linux' },
    { period: '2019.08 - 2021.03', role: 'Lead Softwareentwickler und Architekt', place: 'München', industry: 'Banking', description: 'Das komplete Online Banking Plattform der deutschen Genossenschaftsbanken wurde neu geschrieben. Unser Team hat die Zwei-Faktor-Authentisierung betreut und damit wichtige Funktionen wie Login und Transaktionsfreigabe zur Verfügung gestellt.', techStack: 'Java 8/11, Spring Boot, Kafka, Oracle 12, OAuth2, OWASP Top 10, Mockito, lombok, mapstruct, git', tools: 'OpenShift, docker, Grafana, Graylog, Dynatrace, istio, SonarQube, DBeaver, Postman, Swagger, Jenkins, maven, linux' },
    { period: '2018.07 - 2019.06', role: 'Senior Softwareentwickler und Technischer Berater', place: 'München', industry: 'Automotive', description: 'Neuentwicklung einer Software zur Verwaltung von Fahrzeug-Kerndaten im Carsharing Umfeld. Das System besteht aus mehreren Microservices in einem Kubernetes cluster (in AWS). Neben den fachlichen Anforderungen muss das System anspruchsvolle funktionelle Anforderungen implementieren – Hochverfügbarkeit und schnelle Verarbeitung von großen Datenmengen.', techStack: 'Java 8, JEE 7, JPA 2.1, Payara Micro 4, MQTT, Postgres, JAX-RS (REST), Mockito, lombok, mapstruct, OWASP Top 10, vue.js, git', tools: 'AWS, Kubernetes, docker, SonarQube, Jenkins, gradle, Kibana, Postman, Swagger, DBeaver, Grafana, linux' },
    { period: '2017.08 - 2018.01 und 2013.07 - 2016.07', role: 'Senior Softwareentwickler und Technischer Berater', place: 'München', industry: 'Banking', description: 'Ablösung des alten Kernsystems einer Bank nach den neuen Anforderungen und Gesetzen. Mehrere alte (Sub-)Systeme und Technologien werden neu entwickelt und in die neue Architektur integriert. Das Datenmodell wird ständig aktualisiert, die Daten werden migriert und transformAblösung des alten Kernsystems einer Bank nach den neuen Anforderungen und Gesetzen. Mehrere alte (Sub-)Systeme und Technologien werden neu entwickelt und in die neue Architektur integriert. Das Datenmodell wird ständig aktualisiert, die Daten werden migriert und transformiert. Enge Zusammenarbeit mit den Requirement Engineers.', techStack: 'Java SE 8, Oracle, PL/SQL, Eclipse RCP, WebServices (SOAP/REST), Tomcat, XML Binding, JAXB, ORM, JPA, Junit, Cobol (basics), C++ (basics), git', tools: 'eclipse, MS Visual Studio 2010, DBeaver, SOAP UI, linux' },
    { period: '2016.09 - 2017.07', role: 'Lead Softwareentwickler', place: 'München', industry: 'AgriTech', description: 'Neuentwicklung von Arduino-basiertes Modul, das Daten aus den Sensoren an einem Server verschickt.', techStack: 'Neuentwicklung von Arduino-basiertes Modul, das Daten aus den Sensoren an einem Server verschickt.', tools: 'eclipse, DBeaver, postman, linux' },
  ];

  return (
    <ProjectsComponent data={projects} />
  );

}

const renderProject = (item, index) => (
  <div className="pr-item" key={index}>
    <span className="pr-item-text">
      <p><strong>Periode:</strong> {item.period}</p>
      <p><strong>Rolle:</strong> {item.role}</p>
      <p><strong>Ort:</strong> {item.place}</p>
      <p><strong>Branche:</strong> {item.industry}</p>
      <p><strong>Beschreibung:</strong> {item.description}</p>
      <p><strong>Tech Stack:</strong> {item.techStack}</p>
      <p><strong>Tools:</strong> {item.tools}</p>
    </span>
  </div>
);
const ProjectsComponent = ({ data }) => (
  <div className="pr-site">
    <div className='pr-header'>
      <h1>Projects</h1>
    </div>
    <div className='pr-content'>
      <div className="pr-container">
        {data
          .filter((item, index) => index % 2 === 0)
          .map(renderProject)}
      </div>
      <div className="pr-container">
        {data
          .filter((item, index) => index % 2 === 1)
          .map(renderProject)}
      </div>
    </div>
  </div>
);