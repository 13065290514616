import React from 'react';
import './Tech.css';

function Services() {
  const serviceSoftwareDevelopment = "Kompexe Software Systeme sind für mich Alltag. Ich lebe Teamarbeit und entwickle mit Leidenschaft Individualsoftware-Lösungen für Unternehmen. Ich arbeite mich schnell in die bestehende Strukturen und fachliche Gegebenheiten ein.";
  const serviceRequirementsEngineering = "Als zertifizierter Requirements Engineer schaffe ich eine erfolgreiche Schnittstelle zwischen dem fachlichen und dem Development Team. Ich berate den Klienten in Bezug auf seine Vorstellungen und dokummentiere diese als umsetzungsfähige technische Anforderungen.";
  const serviceOutsourcing = "In Zusammenarbeit mit Partnern in Bulgarien biete ich Ihnen eine große Bandbreite an Services und Lösungen an - von Website & Web Shop Development bis hin zu messbaren Marketing-Technologien. Ihre großen Vorteile sind, dass Sie hiermit beachtliche Kosten sparen können. Zudem stehe ich Ihnen immer als persönlicher Ansprechpartner zur Seite.";
  const serviceMobileAppDevelopment = "Ich unterstütze und berate Sie gerne bei der Mobile App Entwicklung für iPhone und Android.";

  return (
    <div className='st-svc'>
      <h2>Services</h2>
      <div className='st-service-description'>
        <h3>Enterprise Software Entwicklung</h3>
        <p className="text">{serviceSoftwareDevelopment}</p>
      </div>
      <div className='st-service-description'>
        <h3>Requirements Engineering</h3>
        <p className="text">{serviceRequirementsEngineering}</p>
      </div>
      <div className='st-service-description'>
        <h3>Outsourcing</h3>
        <p className="text">{serviceOutsourcing}</p>
      </div>
      <div className='st-service-description'>
        <h3>Mobile App Entwicklung</h3>
        <p className="text">{serviceMobileAppDevelopment}</p>
      </div>
    </div>
  );
}

function Technologies() {
  const techCoding = "java, kotlin, JEE\ngit, gradle, maven\nSQL, react, bash\nk8s, docker, ansible, vagrant";
  const techDB = "Oracle, PL/SQL\nPostgres\nMySQL\nMongoDB (Grundlagen)";
  const techFrameworks = "quarkus, SpringBoot\nOpen Liberty, Glassfish, Payara Micro\nTomcat, IBM WebSphere";
  const techOS = "Linux, Mac OS, Windows, RaspberryPi OS";
  const techTools = "IntelliJ, eclipse, VSCode\nDBeaver, TOAD, Oracle SQL Developer\nPostman, SoapUI, MQTT Spy\nGitlab, github, Jenkins, Sonar";
  const techProcesses = "Microservices, CI/CD\nSecure Coding, Design Patterns";

  return (
    <div className='st-tech'>
      <h2>Technologien</h2>
      <TechDescription title="Coding" tech={techCoding} />
      <TechDescription title="Datenbanken" tech={techDB} />
      <TechDescription title="Frameworks / Application Server" tech={techFrameworks} />
      <TechDescription title="Betriebssysteme" tech={techOS} />
      <TechDescription title="Werkzeuge" tech={techTools} />
      <TechDescription title="Prozesse" tech={techProcesses} />
    </div >
  );
}

function TechDescription({ title, tech: techDesc }) {
  const techLines = techDesc.split('\n');

  return (
    <div className='st-tech-description'>
      <h3>{title}</h3>
      <p className="text">
        {techLines.map((item, index) => <React.Fragment key={index}>{item}<br/></React.Fragment>)}
      </p>
    </div>
  );
}

export function Tech() {
  return (
    <div className='st-site'>
      <div className='st-header'>
        <h1>Services und Technologien</h1>
      </div>
      <div className='st-content'>
        <Services />
        <Technologies />
      </div>
    </div>
  );
}
